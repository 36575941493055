import React from "react"

import { ResolvedStyle } from "../../DMTokenPreview.types"

export type BorderSwatchProps = Pick<
  ResolvedStyle,
  "borderStyle" | "borderWidth" | "borderColor" | "borderRadius"
>

export function BorderSwatch(props: BorderSwatchProps) {
  const { borderStyle, borderWidth, borderColor, borderRadius } = props

  // todo: add some visualizations when a border width is 0

  const constructedCSS = {
    borderStyle: borderStyle || "solid",
    borderWidth: borderWidth || "1px",
    borderColor: borderColor || "#000000",
    borderRadius: borderRadius || undefined,
  }

  return (
    <div
      className="bg-elevation-base h-full w-full rounded"
      style={constructedCSS}
    />
  )
}
