"use client"

import * as React from "react"
import { ComponentType, ReactNode } from "react"
import { Components, GroupedVirtuoso, Virtuoso } from "react-virtuoso"

import {
  ComboboxProvider as ComboboxProviderPrimitive,
  ComboboxProviderProps as ComboboxProviderPropsPrimitive,
} from "@ariakit/react"

import { cn } from "../../utils/cn"

export type DMListboxProps = ComboboxProviderPropsPrimitive & {
  className?: string
}

export type DMListboxSelectedValue = string | string[]

type DMListBoxContentVirtualizedProps = {
  itemContent: (index: number, groupIndex?: number) => ReactNode
  emptyPlaceholder: ComponentType
} & (
  | { totalCount: number; groupCounts?: never; groupContent?: never }
  | {
      totalCount?: never
      groupCounts: number[]
      groupContent: (index: number) => ReactNode
    }
)

const DMListbox = React.forwardRef<
  React.ElementRef<typeof ComboboxProviderPrimitive>,
  DMListboxProps
>(({ className, ...props }, ref) => {
  return (
    <ComboboxProviderPrimitive
      focusShift
      focusLoop={false}
      focusWrap="horizontal"
      includesBaseElement={false}
      resetValueOnSelect={false}
      resetValueOnHide={false}
      {...props}
    >
      <div className={cn("flex flex-col gap-12 min-h-[0px]", className)}>
        {props.children}
      </div>
    </ComboboxProviderPrimitive>
  )
})

const VirtuosoCustomList: Components["List"] = React.forwardRef(
  (props, ref) => {
    return <div className="pr-4" {...props} ref={ref} />
  }
)

/*
 * Virtualization wrapper for DMListboxList and DMListboxGrid.
 * - Should be between DMListbox and DMListboxList/DMListboxGrid.
 * - !!! Don't forget to add `isVirtualized` to DMListboxGrid
 * - Works with both ungrouped and grouped lists.
 * - Ungrouped lists needs to be passed with totalCount prop
 * - Grouped lists needs to be passed with groupCounts and groupContent props, which can be generated with transformGroupDataForVirtualization
 * - Uses Virtuoso under the hood: https://virtuoso.dev/
 */
function DMListboxContentVirtualized(props: DMListBoxContentVirtualizedProps) {
  const {
    totalCount,
    itemContent,
    emptyPlaceholder,
    groupContent,
    groupCounts,
  } = props

  if (groupCounts) {
    return (
      <GroupedVirtuoso
        className="-mr-8 scrollbar-fancy scrollbar-stable overflow-auto h-full col-span-full"
        groupCounts={groupCounts}
        groupContent={groupContent}
        itemContent={itemContent}
        overscan={500}
        components={{
          List: VirtuosoCustomList,
          EmptyPlaceholder: emptyPlaceholder,
          Footer: () => <div className="col-span-full h-[12px]" />,
        }}
      />
    )
  }

  return (
    <Virtuoso
      className="-mr-16 scrollbar-fancy scrollbar-stable overflow-auto [&_[data-test-id='virtuoso-item-list']]:pr-4 grid col-span-full h-full gap-12 scroll-my-[36px] scroll-py-[36px]"
      totalCount={totalCount}
      overscan={500}
      components={{
        List: VirtuosoCustomList,
        EmptyPlaceholder: emptyPlaceholder,
        Footer: () => <div className="col-span-full h-[12px]" />,
      }}
      itemContent={itemContent}
    />
  )
}

VirtuosoCustomList.displayName = "VirtuosoCustomList"
DMListboxContentVirtualized.displayName = "DMListboxContentVirtualized"

DMListbox.displayName = "DMListbox"

export { DMListbox, DMListboxContentVirtualized }
