import * as React from "react"
import { SVGProps } from "react"

export function ShadowDropPartialIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      height={16}
      viewBox="0 0 16 16"
      width={16}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="8"
        cy="8"
        r="5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.1"
      />
      <path
        clipRule="evenodd"
        d="M13.3387 12.5608C13.5534 12.7756 13.5534 13.1239 13.3387 13.3386C13.1027 13.5746 12.8548 13.7919 12.5968 13.9903C12.356 14.1755 12.0107 14.1305 11.8255 13.8897C11.6403 13.649 11.6853 13.3036 11.9261 13.1184C12.1465 12.9489 12.3586 12.7631 12.5608 12.5608C12.7756 12.346 13.1239 12.346 13.3387 12.5608ZM11.1877 14.2586C11.3038 14.5393 11.1704 14.8609 10.8897 14.9771C10.2754 15.2312 9.63465 15.4008 8.9852 15.4858C8.68401 15.5252 8.40789 15.313 8.36845 15.0118C8.32902 14.7107 8.54121 14.4345 8.8424 14.3951C9.39763 14.3224 9.94494 14.1775 10.4692 13.9606C10.7499 13.8445 11.0715 13.9779 11.1877 14.2586ZM7.63155 15.0118C7.59211 15.313 7.31599 15.5252 7.0148 15.4858C6.36535 15.4008 5.72457 15.2312 5.11027 14.9771C4.82959 14.8609 4.6962 14.5393 4.81233 14.2586C4.92846 13.9779 5.25014 13.8445 5.53082 13.9606C6.05506 14.1775 6.60237 14.3224 7.1576 14.3951C7.45879 14.4345 7.67098 14.7107 7.63155 15.0118ZM4.17452 13.8897C3.98932 14.1305 3.644 14.1755 3.40323 13.9903C3.14524 13.7919 2.89732 13.5746 2.66134 13.3386C2.44656 13.1239 2.44656 12.7756 2.66134 12.5608C2.87613 12.346 3.22437 12.346 3.43916 12.5608C3.64141 12.7631 3.85353 12.9489 4.07391 13.1184C4.31468 13.3036 4.35972 13.649 4.17452 13.8897Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
