import * as React from "react"
import { SVGProps } from "react"

export function ShadowInnerFullIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      height={16}
      viewBox="0 0 16 16"
      width={16}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="8"
        cy="8"
        r="5"
        stroke="currentColor"
        strokeDasharray="2 2"
        strokeLinecap="round"
        strokeWidth="1.1"
      />
      <circle
        cx="8"
        cy="8"
        r="7"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.1"
      />
    </svg>
  )
}
