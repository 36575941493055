import * as React from "react"
import { SVGProps } from "react"

export const IconHide = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M7.057 7.058a1.333 1.333 0 0 0 1.886 1.885m2.178 2.172A5.8 5.8 0 0 1 8 12q-3.6 0-6-4 1.272-2.12 2.88-3.116m1.907-.764C7.186 4.04 7.593 3.999 8 4q3.6 0 6 4-.667 1.11-1.425 1.913M2 2l12 12"
    />
  </svg>
)
