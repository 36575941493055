import { DMSpacing } from "../../types/spacing"

type DividerBleedSpacing = {
  [key in DMSpacing]: string | null
}

export const dividerBleedSpacing: DividerBleedSpacing = {
  0: null,
  4: "-mx-4",
  8: "-mx-8",
  12: "-mx-12",
  16: "-mx-16",
  20: "-mx-20",
  24: "-mx-24",
  28: "-mx-28",
  32: "-mx-32",
  36: "-mx-36",
  40: "-mx-40",
}
