import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { ShadowTokenMetadata } from "../../DMTokenPreview.types"
import { ShadowDropFullIcon } from "../../assets/ShadowDropFull"
import { ShadowDropPartialIcon } from "../../assets/ShadowDropPartial"
import { ShadowInnerFullIcon } from "../../assets/ShadowInnerFull"
import { ShadowInnerPartialIcon } from "../../assets/ShadowInnerPartial"

export type ShadowSwatchProps = ShadowTokenMetadata

export function ShadowSwatch(props: ShadowSwatchProps) {
  const { x, y, type } = props

  function calculateShadowRotation(x: number, y: number): number {
    const angle = Math.atan2(y, x)
    const degrees = (180 * angle) / Math.PI

    return ((360 + Math.round(degrees)) % 360) - 90 // round number, avoid decimal fragments, and offset
  }

  const rotationCalculated = calculateShadowRotation(x, y)
  let icon: React.ReactNode
  let partial = true

  if (x === 0 && y === 0) {
    partial = false
  }

  switch (true) {
    case type === "inner" && partial:
      icon = <ShadowInnerPartialIcon />
      break
    case type === "inner" && !partial:
      icon = <ShadowInnerFullIcon />
      break
    case type === "drop" && partial:
      icon = <ShadowDropPartialIcon />
      break
    default:
      icon = <ShadowDropFullIcon />
      break
  }

  return (
    <div
      className={cn("h-full w-full rounded")}
      style={{ transform: `rotate(${rotationCalculated}deg)` }}
    >
      {icon}
    </div>
  )
}
