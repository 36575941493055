import * as React from "react"
import { SVGProps } from "react"

export const IconTextStrikethrough = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M3.333 8h9.334m-2-3.667c-.152-.293-.498-.552-.983-.734C9.2 3.418 8.606 3.324 8 3.333h-.667a2.333 2.333 0 1 0 0 4.667h1.334a2.333 2.333 0 0 1 0 4.667h-1c-.606.01-1.2-.085-1.685-.266-.484-.182-.83-.441-.982-.734"
    />
  </svg>
)
