import * as React from "react"
import { SVGProps } from "react"

export function ReferenceBadgeIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      height={10}
      viewBox="0 0 8 8"
      width={10}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m3 5 2-2M3.667 2l.154-.179A1.667 1.667 0 0 1 6.178 4.18L6 4.333M4.333 6l-.132.178a1.69 1.69 0 0 1-2.74-.54 1.657 1.657 0 0 1 .364-1.817L2 3.667"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".9"
      />
    </svg>
  )
}
