import * as React from "react"

import { IconSearch } from "@supernovaio/icons"

import {
  Combobox as ComboboxPrimitive,
  ComboboxProps as ComboboxPropsPrimitive,
} from "@ariakit/react"

import { cn } from "../../utils/cn"
import { DMIcon } from "../DMIcon"

type Variants = "default" | "dropdown"
type VariantStyles = {
  [key in Variants]: {
    focus: string
    hover: string
    base: string
    icon: React.ReactNode
  }
}

export interface DMListboxSearchInputProps
  extends Omit<
    ComboboxPropsPrimitive,
    | "disabled"
    | "focusable"
    | "composite"
    | "focusOnMove"
    | "accessibleWhenDisabled"
    | "showOnMouseDown"
    | "showOnKeyDown"
    | "showOnChange"
    | "moveOnKeyPress"
  > {
  isDisabled?: boolean
  isFocusable?: boolean
  variant?: "default" | "dropdown"
}

const variants = {
  default: {
    focus:
      "focus-within:ring-2 ring-offset-1 focus-within:border-focus ring-focus-faded hover:focus-within:border-focus",
    hover: "hover:border-neutral",
    base: "text-body rounded bg-elevation-base border border-neutral-faded cursor-text pl-8 transition outline-none",
    icon: <DMIcon svg={IconSearch} color="neutral-faded" />,
  },
  dropdown: {
    focus: "",
    hover: "",
    base: "text-body-small bg-elevation-base border-b border-neutral-faded cursor-text transition outline-none",
    icon: null,
  },
} satisfies VariantStyles

const DMListboxSearchInput = React.forwardRef<
  React.ElementRef<typeof ComboboxPrimitive>,
  DMListboxSearchInputProps
>(
  (
    { isDisabled, children, isFocusable, variant = "default", ...props },
    ref
  ) => {
    return (
      <label
        htmlFor={props.id}
        className={cn(
          "flex items-center h-form-medium",
          "placeholder:text-neutral-placeholder",
          variants[variant].base,
          variants[variant].hover,
          variants[variant].focus,
          props.className
        )}
      >
        {variants[variant].icon}
        <ComboboxPrimitive
          ref={ref}
          autoFocus
          autoSelect
          placeholder="Search..."
          disabled={isDisabled}
          focusable={isFocusable}
          onKeyDown={(e) => {
            if (e.code === "Escape") {
              e.stopPropagation() // to ensure that the dialog doesn't close when pressing ESC
            }
          }}
          type="search"
          {...props}
          className={cn(
            "grow bg-transparent cursor-text pl-8 pr-12 transition outline-none",
            "placeholder:text-neutral-placeholder",
            props.className
          )}
        />
      </label>
    )
  }
)

DMListboxSearchInput.displayName = "DMListboxSearchInput"

export { DMListboxSearchInput }
