import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { IconTheme } from "@supernovaio/icons"

import { DMIcon } from "../../../DMIcon"
import { DMTooltip } from "../../../DMTooltip"

type ThemeIconProps = {
  themes?: string[]
  contrastingColor?: "dark" | "light"
}

export function ThemeIcon({ themes, contrastingColor }: ThemeIconProps) {
  if (!themes || themes.length === 0) {
    return null
  }

  const tooltip =
    themes.length === 1
      ? `Applied theme: ${themes[0]}`
      : `Applied themes:\n${themes.join("\n")}`

  return (
    <div className="absolute top-4 right-4">
      <DMTooltip text={tooltip} className="flex" textAlign="left">
        <DMIcon
          className={cn({
            "text-black": contrastingColor === "dark",
            "text-white": contrastingColor === "light",
          })}
          svg={IconTheme}
        />
      </DMTooltip>
    </div>
  )
}
