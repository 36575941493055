import * as React from "react"
import { SVGProps } from "react"

export const IconTextTitleCase = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M2 12.667v-7a2.333 2.333 0 1 1 4.667 0v7M2 8.667h4.667M14 8v4.667m-4.667-2.334a2.333 2.333 0 1 0 4.667 0 2.333 2.333 0 0 0-4.667 0"
    />
  </svg>
)
