import React from "react"

import {
  IconHide,
  IconShow,
  IconTextLowercase,
  IconTextNone,
  IconTextSmallCaps,
  IconTextStrikethrough,
  IconTextTitleCase,
  IconTextUnderline,
  IconTextUppercase,
} from "@supernovaio/icons"

import { IconSwatch } from "./IconSwatch"

export type OptionsSwatchProps = {
  value: string
}

export function OptionsSwatch(props: OptionsSwatchProps) {
  const { value } = props
  let icon: React.ReactNode

  switch (value.toLowerCase()) {
    case "uppercase":
      icon = <IconTextUppercase />
      break
    case "lowercase":
      icon = <IconTextLowercase />
      break
    case "capitalize":
      icon = <IconTextTitleCase />
      break
    case "smallcaps":
      icon = <IconTextSmallCaps />
      break
    case "underline":
      icon = <IconTextUnderline />
      break
    case "strikethrough":
      icon = <IconTextStrikethrough />
      break
    case "true": // visibility: visible
      icon = <IconShow />
      break
    case "false": // visibility: hidden
      icon = <IconHide />
      break
    default:
      icon = <IconTextNone />
      break
  }

  return <IconSwatch icon={icon} />
}
