import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { ResolvedStyle } from "../../DMTokenPreview.types"
import { ThemeIcon } from "../tiles/ThemeIcon"
import { contrastColor } from "../token-utils"

export type ColorSwatchProps = Pick<ResolvedStyle, "background" | "opacity"> & {
  themes?: string[]
}

export function ColorSwatch(props: ColorSwatchProps) {
  const { background, opacity, themes } = props

  const constructedCSS = {
    background: background || undefined,
    opacity: opacity || undefined,
  }

  return (
    <div
      className={cn(
        "preview-tile",
        "bg-checkered h-full w-full overflow-hidden rounded",
        {
          "border-neutral-faded border":
            background && background.includes("#FFFFFF"),
        }
      )}
    >
      <div
        className={cn("h-full w-full relative", { "bg-black": opacity })}
        style={constructedCSS}
      >
        {themes && (
          <ThemeIcon
            themes={themes}
            contrastingColor={contrastColor(background)}
          />
        )}
      </div>
    </div>
  )
}
