import * as React from "react"
import { SVGProps } from "react"

export const IconTokenLetterSpacing = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M3.333 8V4.333a1.667 1.667 0 0 1 3.334 0V8m0-2.667H3.333m5.334-2.666 2 5.333 2-5.333M3.333 12h9.334m-9.334 0 1.334-1.333M3.333 12l1.334 1.333m8-1.333-1.334 1.333M12.667 12l-1.334-1.333"
    />
  </svg>
)
