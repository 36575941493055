import * as React from "react"
import { SVGProps } from "react"

export const IconSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="m14 14-4-4M2 6.667a4.667 4.667 0 1 0 9.333 0 4.667 4.667 0 0 0-9.333 0"
    />
  </svg>
)
