import * as React from "react"
import { SVGProps } from "react"

export const IconCheckboxStaticChecked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.222 1.333a2.445 2.445 0 0 1 2.441 2.315l.004.13v8.444a2.445 2.445 0 0 1-2.315 2.441l-.13.004H3.778a2.445 2.445 0 0 1-2.441-2.315l-.004-.13V3.778a2.445 2.445 0 0 1 2.315-2.441l.13-.004zm-1.75 4.862a.667.667 0 0 0-.943 0L7.333 8.39l-.862-.861-.062-.056a.667.667 0 0 0-.88.998l1.333 1.334.063.055a.666.666 0 0 0 .88-.055l2.666-2.667.056-.063a.67.67 0 0 0-.056-.88"
    />
  </svg>
)
