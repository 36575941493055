import * as React from "react"
import { SVGProps } from "react"

export function ShadowInnerPartialIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      height={16}
      viewBox="0 0 16 16"
      width={16}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M4.076 11.147a.55.55 0 0 1 .777 0c.211.21.437.395.675.554a.55.55 0 1 1-.612.914 5.571 5.571 0 0 1-.84-.69.55.55 0 0 1 0-.778Zm7.849 0a.55.55 0 0 1 0 .777 5.572 5.572 0 0 1-.84.691.55.55 0 1 1-.613-.914 4.47 4.47 0 0 0 .675-.554.55.55 0 0 1 .777 0Zm-2.41 1.65a.55.55 0 0 1-.432.647 5.581 5.581 0 0 1-2.165 0 .55.55 0 0 1 .213-1.08 4.476 4.476 0 0 0 1.738 0 .55.55 0 0 1 .646.434Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <circle
        cx="8"
        cy="8"
        r="7"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.1"
      />
    </svg>
  )
}
