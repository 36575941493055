import * as React from "react"
import { SVGProps } from "react"

export const IconTokenZindex = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="m4.667 6.333-2 1L8 10l5.333-2.667-2-1M2.667 10 8 12.667 13.333 10M8 7.333V2.667m0 0-2 2m2-2 2 2"
    />
  </svg>
)
