import * as React from "react"
import { SVGProps } from "react"

export const IconShow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M6.667 8a1.333 1.333 0 1 0 2.666 0 1.333 1.333 0 0 0-2.666 0"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M14 8q-2.4 4-6 4T2 8q2.4-4 6-4t6 4"
    />
  </svg>
)
