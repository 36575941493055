import * as React from "react"
import { SVGProps } from "react"

export const IconTextUnderline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M4.667 3.333v3.334a3.333 3.333 0 0 0 6.666 0V3.333m-8 9.334h9.334"
    />
  </svg>
)
