import React from "react"

import {
  DimensionTokenMetadata,
  ResolvedStyle,
} from "../../DMTokenPreview.types"
import { meetsMinimumFontSizeRequirements } from "../token-utils"

export type TextSwatchProps = Pick<
  ResolvedStyle,
  "fontSize" | "fontWeight" | "fontFamily"
> & {
  fontSizeValue?: DimensionTokenMetadata | null | undefined
}

export function TextSwatch(props: TextSwatchProps) {
  const { fontSize, fontFamily, fontWeight, fontSizeValue } = props

  const constructedCSS = {
    fontSize:
      fontSizeValue &&
      meetsMinimumFontSizeRequirements(fontSizeValue.value, fontSizeValue.unit)
        ? fontSize
        : "11px",
    fontFamily: fontFamily || "Inter",
    fontWeight: fontWeight || "400",
  }

  return (
    <div
      className="bg-elevation-base text-neutral flex h-full w-full select-none items-center justify-center rounded"
      style={constructedCSS}
    >
      Ag
    </div>
  )
}
