import * as React from "react"
import { SVGProps } from "react"

export const IconTheme = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M5.333 6.333a4.333 4.333 0 1 0 8.667 0 4.333 4.333 0 0 0-8.667 0"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M2 9.667a4.334 4.334 0 1 0 8.667 0 4.334 4.334 0 0 0-8.667 0"
    />
  </svg>
)
