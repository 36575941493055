import * as React from "react"
import { SVGProps } from "react"

export const IconPage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M9.333 2v2.667a.667.667 0 0 0 .667.666h2.667M9.333 2H4.667a1.333 1.333 0 0 0-1.334 1.333v9.334A1.333 1.333 0 0 0 4.667 14h6.666a1.334 1.334 0 0 0 1.334-1.333V5.333M9.333 2l3.334 3.333M6 6h.667M6 8.667h4m-4 2.666h4"
    />
  </svg>
)
