import React from "react"

import { DMIcon } from "../../../DMIcon"

export type IconSwatchProps = {
  icon: React.ReactElement
}

export function IconSwatch(props: IconSwatchProps) {
  const { icon } = props

  return (
    <div className="h-full w-full rounded">
      <DMIcon size="small" svg={icon} />
    </div>
  )
}
