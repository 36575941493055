"use client"

import * as React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import * as SeparatorPrimitive from "@radix-ui/react-separator"

import { DMSpacing } from "../../types/spacing"

import { dividerBleedSpacing } from "./utils"

export interface DMDividerProps
  extends React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root> {
  variant?: "neutral" | "neutral-faded"
  bleed?: DMSpacing
}

export const DMDivider = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  DMDividerProps
>(
  (
    {
      className,
      orientation = "horizontal",
      decorative = true,
      variant = "neutral-faded",
      bleed = 0,
      ...props
    },
    ref
  ) => (
    <SeparatorPrimitive.Root
      ref={ref}
      className={cn(
        "shrink-0 rounded",
        {
          "bg-border-neutral-faded": variant === "neutral-faded",
          "bg-border-neutral": variant === "neutral",
        },
        orientation === "horizontal"
          ? "h-[1px]"
          : "h-full min-h-[20px] w-[1px]",
        bleed && orientation === "horizontal" && dividerBleedSpacing[bleed],
        className
      )}
      decorative={decorative}
      orientation={orientation}
      {...props}
    />
  )
)

DMDivider.displayName = "DMDivider"
