import * as React from "react"
import { SVGProps } from "react"

export const IconTokenRadius = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M13.333 8V5.333a2.667 2.667 0 0 0-2.666-2.666H8m-2.667 0v.006m-2.666-.006v.006m0 2.66v.007m0 2.66v.007m10.666 2.66v.006m-10.666-.006v.006m10.666 2.66v.007m-2.666-.007v.007M8 13.333v.007m-2.667-.007v.007m-2.666-.007v.007"
    />
  </svg>
)
