import * as React from "react"
import { SVGProps } from "react"

export const IconTokenString = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M2 10.667v-4a1.333 1.333 0 0 1 2.667 0v4M2 8.667h2.667m2-3.334v4m0 0a1.333 1.333 0 1 0 2.666 0v-.666a1.333 1.333 0 1 0-2.666 0zM13.82 8a1.333 1.333 0 0 0-2.488.667v.666a1.333 1.333 0 0 0 2.484.674"
    />
  </svg>
)
