import * as React from "react"
import { SVGProps } from "react"

export const IconTokenDuration = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M8 8a6.7 6.7 0 0 1-1.448 1.885 2.666 2.666 0 1 1 0-3.77A6.7 6.7 0 0 1 8 8m0 0a6.7 6.7 0 0 1 1.448-1.885 2.667 2.667 0 1 1 0 3.77A6.7 6.7 0 0 1 8 8"
    />
  </svg>
)
