import * as React from "react"
import { SVGProps } from "react"

export const IconTokenBlur = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M3.333 3.333h2m2.667 0h6M2 6.667h7.333m2.667 0h.667M3.333 10h3.334m2.666 0H14M2 13.333h6m2.667 0h2"
    />
  </svg>
)
