import * as React from "react"

import { DMIllustratedMessage } from "../DMIllustratedMessage"

export type DMListboxEmptyProps = {
  title?: string
  description?: string
  illustration?: React.ComponentType | React.ReactElement
}

export function DMListboxEmpty(props: DMListboxEmptyProps) {
  const { title, description = "No items found.", illustration } = props

  return illustration ? (
    <div className="flex justify-center w-full h-full py-24 col-span-full">
      <DMIllustratedMessage
        size="small"
        illustration={illustration}
        title={title}
        description={description}
      />
    </div>
  ) : (
    <div className="text-body text-semibold text-neutral">{description}</div>
  )
}
