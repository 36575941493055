import * as React from "react"
import { SVGProps } from "react"

export const IconTextLowercase = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M6.667 8v4.667M14 8v4.667M2 10.333a2.333 2.333 0 1 0 4.667 0 2.333 2.333 0 0 0-4.667 0m7.333 0a2.333 2.333 0 1 0 4.666 0 2.333 2.333 0 0 0-4.666 0"
    />
  </svg>
)
