import * as React from "react"
import { SVGProps } from "react"

export const IconTokenDimension = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="m10.667 4.667-1-1m-1 3-1-1m-1 3-1-1m-1 3-1-1M11.333 2 14 4.667 4.667 14 2 11.333z"
    />
  </svg>
)
