import * as React from "react"
import { SVGProps } from "react"

export const IconTokenParagraphSpacing = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M8.667 2.667v10.666m2.666-10.666v10.666m1.334-10.666H6.333a3 3 0 1 0 0 6h2.334"
    />
  </svg>
)
