import * as React from "react"
import { SVGProps } from "react"

export const IconHelp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M8 10.667v.006m0-2.006a1.333 1.333 0 1 0-.251-2.641A1.32 1.32 0 0 0 7 6.467M2 8a6 6 0 1 0 12 0A6 6 0 0 0 2 8"
    />
  </svg>
)
